import logo from './logo.svg';
import './App.css';


import {Fragment,useEffect,useState}from 'react';
import * as React from 'react';
import {RouteNavigation} from "./RouteNavigation";
import {AppContextApi} from "./AppContext";
import {ThemeProvider,createTheme} from '@mui/material/styles';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Link} from "react-router-dom";
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, ButtonGroup, Typography } from '@mui/material';

//#FEA116
//#2B476A


function App() {  

  const ResponsiveDimention = useTheme();
  const mobile_xs = useMediaQuery(ResponsiveDimention.breakpoints.only('xs'));
  const tablet_sm = useMediaQuery(ResponsiveDimention.breakpoints.only('sm'));
  const desktop_md = useMediaQuery(ResponsiveDimention.breakpoints.only('md'));
  const desktop_lg = useMediaQuery(ResponsiveDimention.breakpoints.only('lg'));
  const desktop_xl = useMediaQuery(ResponsiveDimention.breakpoints.only('xl'));
  
  
  const [UserProfile,setUserProfile] = useState({
    CompanyProfile:{
       company_name:'FemsBay',
       company_logo:'logo.png',
       company_motto:'WE WORK AND GROW TOGETHER',
       theme_maincolor: "#FBB117",
       theme_subcolor: "#151B54",
       theme_decoration_color: "#FF1493",
       phone_number1:'+255768120994',
       phone_number2:'+255620169807',
       whatsap_number:'+255768120994',
       office_location:'Ilemela Mwanza ,Tanzania',
       company_email:'info@femsbay.com',
       facebook_handle:'jengaafrica_tz',
       instagram_handle:'jengaafrica_tz',
       instagram_link:'https://',
       facebook_link:'https://',
       registration_link:'https://account.femsbay.com/auth/registration',
       login_link:'https://account.femsbay.com/auth/login'
      },
     PersonalProfile:{
      username_email:null,
      first_name:null,
      last_name:null,
      middle_name:null,
      profile_photo:null,
      primary_email:null,
      user_type:null
     },
     Permission:{
      role:null,//admin || branch_nanager
      execute: null,
      write: null,
      read:null,
     },
  
  })
  
  

  const theme = createTheme({
    palette:{
      primary:{
        main:`${UserProfile.CompanyProfile.theme_maincolor}`
      },
      secondary:{
        main:`${UserProfile.CompanyProfile.theme_subcolor}`
      },
      info:{
        main:`${UserProfile.CompanyProfile.theme_decoration_color}`
      },
      // mode: 'dark',
      background:{
        //paper:'#090B23',
        //default:'#090B23'
      }
    },
    typography:{
      //fontFamily:'Comic Sans Ms',
      body1:{
        fontSize:'1.1rem',
      
      },
      // h1:{
      //   color:'red'
      // }
    },
    shape:{
      borderRadius:0,
    },
    //spacing:8,
    overrides:{
      MuiButton:{
        root:{
          textTransform:'none',
          
        }
      },
  
    },
    props:{
      MuiButton:{
        disableRipple:true,
        // variant:'text'
      },
      MuiButtonGroup:{
        disableRipple:true,
        // variant:'outlined'
      },
      MuiTextField:{
        InputLabelProps:{
          shrink:true
        }
      }
      
    }
  
  })   
  
  
  var ResponsiveMediaQuery ={
    mobile_xs:mobile_xs,
    tablet_sm:tablet_sm,
    desktop_md:desktop_md,
    desktop_lg:desktop_lg,
    desktop_xl:desktop_xl
}
  
  
var SharedFunction={}
  
  

return (
<AppContextApi.Provider value={{
   UserProfile:UserProfile,
   Functions:SharedFunction,
   theme:theme,
   ResponsiveMediaQuery:ResponsiveMediaQuery
}}>
<div className="App">
<div className="container-xxl bg-white p-0">
  {/* Header Start */}
  <div className="container-fluid bg-dark px-0">
    <div className="row gx-0">
      <div className="col-lg-3 bg-dark d-none d-lg-block">
        <a href="/" className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
          <div className='brandlogoFrame'>
          <img src={UserProfile.CompanyProfile.company_logo} alt='' style={{maxWidth:60}}/>



           <Typography variant='h3' sx={{color:'#fff'}}>{UserProfile.CompanyProfile.company_name}</Typography>

           </div>

        </a>
      </div>
      <div className="col-lg-9">
        <div className="row gx-0 bg-white d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center py-2 me-4">
              <i className="fa fa-envelope text-primary me-2" />
              <p className="mb-0">{UserProfile.CompanyProfile.company_email}</p>
            </div>
            <div className="h-100 d-inline-flex align-items-center py-2">
              <i className="fa fa-phone-alt text-primary me-2" />
              <p className="mb-0">{UserProfile.CompanyProfile.phone_number1}</p>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="d-inline-flex align-items-center py-2">
              <a className="me-3" href><i className="fab fa-facebook-f" /></a>
              <a className="me-3" href><i className="fab fa-twitter" /></a>
              <a className="me-3" href><i className="fab fa-linkedin-in" /></a>
              <a className="me-3" href><i className="fab fa-instagram" /></a>
              <a className href><i className="fab fa-youtube" /></a>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
          <a href="index.html" className="navbar-brand d-block d-lg-none">
            <h1 className="m-0 text-primary text-uppercase">{UserProfile.CompanyProfile.company_name}</h1>
          </a>
          <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
            <div className="navbar-nav mr-auto py-0">
              <NavLink to="/" className="nav-item nav-link active">Home</NavLink>
              <NavLink to="/services" className="nav-item nav-link">Services</NavLink>
              <NavLink to="/products" className="nav-item nav-link">Products</NavLink>
              <NavLink to="/about" className="nav-item nav-link">About</NavLink>
              <NavLink to="/" className="nav-item nav-link">Contact</NavLink>
              {ResponsiveMediaQuery.mobile_xs===true || ResponsiveMediaQuery.tablet_sm===true || ResponsiveMediaQuery.desktop_md===true?<Fragment>
               
                <NavLink to={UserProfile.CompanyProfile.login_link} className="nav-item nav-link">Login</NavLink>

                <NavLink to={UserProfile.CompanyProfile.registration_link} className="nav-item nav-link">Register</NavLink>

              </Fragment>:null}
              
              
              {/* <div className="nav-item dropdown">
                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">More</a>
                <div className="dropdown-menu rounded-0 m-0">
                  <a href="booking.html" className="dropdown-item">Booking</a>
                  <a href="team.html" className="dropdown-item">Our Team</a>
                  <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                </div>
              </div> */}
  
            </div>
            <a href="#" className="btn btn-primary rounded-0 py-4 px-md-5 d-none d-lg-block" id='TopRegistrationLink'>
             <a href={UserProfile.CompanyProfile.registration_link} style={{color:'#ffff'}}>Register</a>
                &nbsp;&nbsp; | &nbsp;&nbsp;
             <a href={UserProfile.CompanyProfile.login_link} style={{color:'#ffff'}}>Login</a>
            <i className="fa fa-arrow-right ms-3" />
            
            </a>
          </div>
        </nav>
      </div>
    </div>
  </div>
  {/* Header End */}

   <RouteNavigation />
  {/* Newsletter Start */}
  <div className="container newsletter mt-5 wow fadeIn" data-wow-delay="0.1s">
    <div className="row justify-content-center">
      <div className="col-lg-10 border rounded p-1">
        <div className="border rounded text-center p-1">
          <div className="bg-white rounded text-center p-5">
            <h4 className="mb-4">Subscribe Our <span className="text-primary text-uppercase">Newsletter</span></h4>
            <div className="position-relative mx-auto" style={{maxWidth: 400}}>
              <input className="form-control w-100 py-3 ps-4 pe-5" type="text" placeholder="Enter your email" />
              <button type="button" className="btn btn-primary py-2 px-3 position-absolute top-0 end-0 mt-2 me-2">Submit</button>
            
            <br />
             <ButtonGroup size='large'> 
                 <a href={UserProfile.CompanyProfile.login_link}><Button variant='contained'>Login</Button></a>  
                  &nbsp;   &nbsp;   &nbsp;   &nbsp;
                  <a href={UserProfile.CompanyProfile.registration_link}> <Button variant='outlined' color='success'>Register</Button> </a> 
             </ButtonGroup>
            
            
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Newsletter Start */}
  {/* Footer Start */}
  <div className="container-fluid bg-dark text-light footer wow fadeIn" data-wow-delay="0.1s">
    <div className="container pb-5">
      <div className="row g-5">
        <div className="col-md-6 col-lg-4">
          <div className="bg-secondary rounded p-4">
            <a href="index.html">
              {/* <h1 className="text-white text-uppercase mb-3">Hotelier</h1> */}
              <img src={UserProfile.CompanyProfile.company_logo} alt='' style={{maxWidth:'80%'}}/>
            
            </a>
            <p className="text-white mb-0">
            FemsBay is a leading affiliate and digital marketing firm dedicated to identifying, 
            developing, and capitalizing on digital Marketing and sales opportunities. 
            Our core mission is to harness and explore the vast array of sales, marketing 
            and financial prospects available in today's dynamic market.....
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <h6 className="section-title text-start text-primary text-uppercase mb-4">Contact</h6>
          <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />{UserProfile.CompanyProfile.office_location}</p>
          <p className="mb-2"><i className="fa fa-phone-alt me-3" />{UserProfile.CompanyProfile.phone_number1}</p>
          <p className="mb-2"><i className="fa fa-phone-alt me-3" />{UserProfile.CompanyProfile.phone_number2}</p>
          <p className="mb-2"><i className="fa fa-envelope me-3" />{UserProfile.CompanyProfile.company_email}</p>
          <div className="d-flex pt-2">
            <a className="btn btn-outline-light btn-social" href><i className="fab fa-twitter" /></a>
            <a className="btn btn-outline-light btn-social" href><i className="fab fa-facebook-f" /></a>
            <a className="btn btn-outline-light btn-social" href><i className="fab fa-youtube" /></a>
            <a className="btn btn-outline-light btn-social" href><i className="fab fa-linkedin-in" /></a>
          </div>
        </div>
        <div className="col-lg-5 col-md-12">
          <div className="row gy-5 g-4">
            <div className="col-md-6">
              <h6 className="section-title text-start text-primary text-uppercase mb-4">Company</h6>
              <a className="btn btn-link" href>About Us</a>
              <a className="btn btn-link" href>Contact Us</a>
              <a className="btn btn-link" href>Privacy Policy</a>
              <a className="btn btn-link" href>Terms &amp; Condition</a>
              <a className="btn btn-link" href>Support</a>
            </div>
            <div className="col-md-6">
              <h6 className="section-title text-start text-primary text-uppercase mb-4">Services</h6>
              <a className="btn btn-link" href>Food &amp; Restaurant</a>
              <a className="btn btn-link" href>Tour&amp; Travelling</a>
              <a className="btn btn-link" href>Tour &amp; Enternainment</a>
              <a className="btn btn-link" href>Event &amp; Party</a>
              <a className="btn btn-link" href>Explore &amp; Nature</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="copyright">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            © <a className="border-bottom" href="#">{UserProfile.CompanyProfile.company_name}</a>, All Right Reserved. 
            {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
            {/* Designed By <a className="border-bottom" href="https://htmlcodex.com">HTML Codex</a> */}
          </div>
          <div className="col-md-6 text-center text-md-end">
            <div className="footer-menu">
              <a href>Home</a>
              <a href>Cookies</a>
              <a href>Help</a>
              <a href>FQAs</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}
  {/* Back to Top */}
  <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
</div>


</div>
</AppContextApi.Provider>
);
}

export default App;
