import React ,{Fragment,useEffect,useContext}from 'react';
import './AboutPage.scss';
import {useHistory,useLocation,useParams,Link} from "react-router-dom";
import Grid from '@mui/material/Grid/Grid';
import {AppContextApi} from "../../AppContext";
import {TopBanner} from "../TopBanner/TopBanner";
import {TaskAlt,CheckCircle} from '@mui/icons-material';
import {InvestmentSector} from "../InvestmentSector/InvestmentSector";
import {CompanyCoreValue} from "../CompanyCoreValue/CompanyCoreValue";





const  AboutPage = ({display_mission=true,display_vision=true}) => {
const Applicationstate = useContext(AppContextApi)
const {ResponsiveMediaQuery,UserProfile,Functions} = Applicationstate
const {CompanyProfile} = UserProfile
const {theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 
const {mobile_xs,tablet_sm} = ResponsiveMediaQuery


const {company_logo ,company_name} = CompanyProfile



const History = useLocation()

const {pathname} = History










useEffect(()=>{ //COMPONENT DID MOUNT



return()=>{//<<<<<<<<<<<----------------Component Will Unmount


}
},[]) // eslint-disable-line react-hooks/exhaustive-deps










return ( ///-------------- RETURN HTML------------------
<Fragment>
{pathname==='/about'?
<TopBanner Title='About Us' CurrentPage='about us' />
:null}
<div className="container-xxl py-3">
    <div className="container">

      <div className="row g-1 align-items-center">
        <div className="col-lg-6">
          <h6 className="section-title text-start text-primary text-uppercase">About Us</h6>
          <h1 className="mb-4">Welcome to <span className="text-primary text-uppercase">{company_name}</span></h1>
          <p className="mb-4"> 
    
          {company_name} is a leading affiliate and digital marketing firm dedicated to identifying, 
          developing, and capitalizing on digital Marketing and sales opportunities. Our core mission
           is to harness and explore the vast array of sales, marketing and financial prospects available
            in today's dynamic market.
          We offer comprehensive and rigorous sales and marketing training programs designed to cultivate
           highly skilled professionals capable of seizing and maximizing these opportunities.

          
          </p>
          <div className="row g-3 pb-4">
            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="border rounded p-1">
                <div className="border rounded text-center p-4">
                  <i className="fa fa-hotel fa-2x text-primary mb-2" />
                  <h2 className="mb-1" data-toggle="counter-up">123</h2>
                  <p className="mb-0">Marketings</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="border rounded p-1">
                <div className="border rounded text-center p-4">
                  <i className="fa fa-users-cog fa-2x text-primary mb-2" />
                  <h2 className="mb-1" data-toggle="counter-up">123</h2>
                  <p className="mb-0">Newtworking</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="border rounded p-1">
                <div className="border rounded text-center p-4">
                  <i className="fa fa-users fa-2x text-primary mb-2" />
                  <h2 className="mb-1" data-toggle="counter-up">12</h2>
                  <p className="mb-0">Sells</p>
                </div>
              </div>
            </div>
          </div>
           
           {pathname !=='/about'?<Link className="btn btn-primary py-3 px-5 mt-2" to='/about'>Read More</Link>:null}   
              
        </div>

        <div className="col-lg-6">
        <Grid container spacing={1}>

           <Grid item xs={12}>
              <div className='AboutPhotoFrame' style={{backgroundImage:'url(image/network.webp)'}}>
                      <div className='InnerDivFrame'>

                          <img src={company_logo} style={{maxWidth:'100%'}}/>

                      </div>

              </div>
              <div style={{width:'100%',height:100}}></div>
             </Grid>

             <Grid item xs={12}>
             <h1 className="mb-4">Fast Glowing <span className="text-primary text-uppercase">Market Place</span></h1>
             
             <p className="mb-4"> 

                At {company_name} , we pride ourselves on our ability to innovate and adapt in the fast-paced digital
                landscape. By leveraging cutting-edge technologies and methodologies, we not only identify
                emerging trends and opportunities but also formulate strategic approaches to optimize their
                potential. Our training programs are meticulously crafted to equip individuals with the
                knowledge, skills, and confidence needed to excel in the competitive world of sales and
                marketing.

    
              </p>

             </Grid>

          </Grid>
        </div>


      </div>

      {pathname==='/about'?
      <div className="row g-5 align-items-center" style={{paddingTop:10}}>
        
        
        <div className="col-lg-6">
          <h6 className="section-title text-start text-primary text-uppercase">Our Mission</h6>
          <h1 className="mb-4">Explore our <span className="text-primary text-uppercase">Mission</span></h1>
          <p className="mb-4"> 

            Our mission is to leverage the immense potential of digital and sales opportunities 
            to generate substantial value for our clients and stakeholders. We aim to achieve 
            this by cultivating a robust network of highly skilled sales and marketing professionals.
            These professional through their interconnected networks, are prepared to identify, 
            utilize, and optimize the opportunities available in the market. By fostering an 
            environment of continuous learning and collaboration, we ensure that our network is
            always at the forefront of industry advancements, driving growth and success for 
            all parties 

              </p>

        </div>


        <div className="col-lg-6">
          <div className="row g-3">
            <div className="col-12 text-start">
              <img style={{maxWidth:'80%'}} className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s" src="image/sales&marketing.png" />
            </div>
          </div>
        </div>
      </div>
      :null}

      {pathname==='/about'?
      <div className="row g-5 align-items-center" style={{paddingTop:20}}>
      <div className="col-lg-6">
          <div className="row g-5">
            <div className="col-6 text-end">
              <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.1s" src="image/aboutpage-image.jpg" style={{marginTop: '25%'}} />
            </div>
            <div className="col-6 text-start">
              <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s" src="image/aboutpage-vision-section.jpg" />
            </div>
          </div>
        </div>
        
        <div className="col-lg-6">
          <h6 className="section-title text-start text-primary text-uppercase">Our Vision</h6>
          <h1 className="mb-4">Explore Our Vision <span className="text-primary text-uppercase"></span></h1>
          <p className="mb-4"> 
              Our vision is to be a global leader in affiliate and digital marketing, renowned for our
              innovative approaches, exceptional training programs, and our ability to consistently 
              deliver outstanding results. We aspire to set the standard for excellence in the industry,
              fostering a culture of continuous improvement professional and financial growth.
           </p>
        </div>
      </div>
      :null}


    </div>
  </div>
  {/* About End */}

 {pathname==='/about'? <CompanyCoreValue /> :null}
 
</Fragment>
);

// AboutPage.propTypes = {}
          
// AboutPage.defaultProps ={}



}
export{AboutPage}

