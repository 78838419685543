import React ,{Fragment,useEffect,useContext}from 'react';
import './Productpage.scss';
import {useHistory,useLocation,useParams,Link} from "react-router-dom";
import {AppContextApi} from "../../AppContext";
import {AboutPage} from "../AboutPage/AboutPage";
import {Services} from "../Services/Services";
import Typography from '@mui/material/Typography';
import {OurTeam} from "../OurTeam/OurTeam";
import {CompanyObjective} from "../CompanyObjective/CompanyObjective";
import {TopBanner} from "../TopBanner/TopBanner";
import Grid from '@mui/material/Grid/Grid';





const  Productpage = ({props}) => {

  const Applicationstate = useContext(AppContextApi)
  const {ResponsiveMediaQuery,UserProfile,Functions} = Applicationstate
  const {CompanyProfile} = UserProfile
  const {theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 
  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery
  
  const {company_logo} = CompanyProfile
  
  
  
  const History = useLocation()
  
  const {pathname} = History
  
  
  


useEffect(()=>{ //COMPONENT DID MOUNT

  //$(window).scrollTop(20)//scroll to top of the component





return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps










return(///-------------- RETURN HTML------------------
<Fragment>
<TopBanner Title='our products' CurrentPage='products' />

<div className="container-xxl py-5">
  <div className="container">
    <div className="text-center wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
      <h6 className="section-title text-center text-primary text-uppercase">Our Services</h6>
      <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Products</span></h1>
  
          <p class="text-body mb-0">

          we produce natural herbal products that have been cherished for centuries across
          various cultures for their medicinal and health benefits. Derived from plants and
          herbs, our products offer a holistic approach to health and wellness by harnessing
          the natural compounds found in botanicals. Unlike synthetic medications, our natural
          herbal remedies often have fewer side effects and provide a range of benefits,
            from detoxifying the body and boosting the immune system to enhancing overall 
            well-being. By embracing the power of nature, we aim to support and improve health in 
            a gentle yet effective manner, promoting a balanced and natural way of living.

          </p>
      </div>
      <br />



  <Grid container spacing={1} style={{backgroundImage:'url(image/products/leaf-background.jpg)'}}>
    <Grid item xs={12} sm={6}>
     <div className='ProductDisplayFrame'>

      <img src='image/products/detox.png' alt=''style={{maxWidth:'50%',maxHeight:'50%'}}/>
      <div className='productLabel' style={{backgroundColor:theme_maincolor}}><Typography variant='h3' color='#fff'>FEMS DETOX</Typography></div>

     </div>

    </Grid>

    <Grid item xs={12} sm={6}>
     <div className='ProductDetailFrame'>
       <Typography variant='h3'>BENEFIT OF FEMS DETOX</Typography>
       <ul>
          <li>Removing toxins from the body</li>
          <li>Emergency treatment for someone who has ingested poison</li>
          <li>Reducing the effects of drugs</li>
          <li>Purifying the blood</li>
          <li>Cleansing the intestines</li>
       </ul>
     </div>
    </Grid>
  </Grid>

  <br /><br />
  <Grid container spacing={1} style={{backgroundImage:'url(image/products/fruits-background.jpg)'}}>
    <Grid item xs={12} sm={6}>
     <div className='ProductDisplayFrame'>

      <img src='image/products/nalof.png' alt=''style={{maxWidth:'50%',maxHeight:'50%'}}/>
      <div className='productLabel' style={{backgroundColor:theme_maincolor}}><Typography variant='h3' color='#fff'>FEMS NALOF</Typography></div>

     </div>

    </Grid>

    <Grid item xs={12} sm={6}>
     <div className='ProductDetailFrame'>
       <Typography variant='h3'>BENEFIT OF FEMS NALOF</Typography>
       <ul>
          <li>Increases CD4 count</li>
          <li>Enhances memory</li>
          <li>Activates cells throughout the body</li>
          <li>Improves vision</li>
          <li>Boosts sexual strength</li>
          <li>Enhances heart function</li>
          <li>Supports lung function</li>
          <li>Improves blood circulation</li>
          <li>Promotes hair health</li>
          <li>Enhances skin health.</li>
          <li>Eliminates bad fats from the body</li>
          <li>Aids in weight loss</li>
          <li>Strengthens digestion</li>
          <li>Enhances brain health</li>
          <li>Improves eye health</li>
          <li>Provides cancer protection</li>
          <li>Provides diabetes protection</li>
          <li>Relieves insomnia</li>
          <li>Reduces chronic fatigue</li>
          <li>Strengthens bones</li>
          <li>Cleanses the kidneys, etc</li>
       </ul>
     </div>
    </Grid>
  </Grid>
  <div className="text-center wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
      <h6 className="section-title text-center text-primary text-uppercase">Become our Argent/ambassodor of our products</h6>
      <h1 className="mb-5">Contact us to become our sales argent/ambassador <span className="text-primary text-uppercase"></span></h1>
  
          <p class="text-body mb-0">

   
          </p>
      </div>
  </div>
</div>

</Fragment>
);

// AboutPage.propTypes = {}
          
// AboutPage.defaultProps ={}



}
export{Productpage}

