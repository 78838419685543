import React ,{Fragment,useEffect}from 'react';
import {Route,Switch} from 'react-router-dom';

import './RouteNavigation.scss';
//import Cookies from 'js-cookie';
import {Homepage} from "./Components/Homepage/Homepage";
import {AboutPage} from "./Components/AboutPage/AboutPage";
import {Services} from "./Components/Services/Services";
import {Project} from "./Components/Project/Project";
import {Productpage} from "./Components/Productpage/Productpage";





const  RouteNavigation = (props) => {





useEffect(()=>{//COMPONENT DID MOUNT







 
    


},[])




  



const PageNotFound =()=>{

return (
<React.Fragment>


    <div className='pagenotfound-wrapper'>


    <h1>404 PAGE NOT FOUND</h1>


    </div>

</React.Fragment>
)
}










return (  //=----RETURN MAIN HTML NAVIGATION
<Fragment>

<Switch>


<Route  exact={true} path={`/`} render={(props)=>(<Homepage />)} />

<Route  exact={true} path={`/services`} render={(props)=>(<Services />)} />

<Route  exact={true} path={`/products`} render={(props)=>(<Productpage />)} />

<Route  exact={true} path={`/about`} render={(props)=>(<AboutPage />)} />

<Route  exact={true} path={`/project`} render={(props)=>(<Project />)} />

<Route  render={(props)=>(<PageNotFound />)} />



</Switch>

</Fragment>
);
}
export {RouteNavigation};

