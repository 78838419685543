import React ,{Fragment,useEffect,useContext}from 'react';
import './Services.scss';
import $ from 'jquery';
import {useHistory,useLocation,useParams,Link} from "react-router-dom";
import Grid from '@mui/material/Grid/Grid';
import {AppContextApi} from "../../AppContext";
import {TopBanner} from "../TopBanner/TopBanner";
import {TaskAlt,CheckCircle} from '@mui/icons-material';



const  Services = ({display_mission=true,display_vision=true}) => {

const Applicationstate = useContext(AppContextApi)

const {ResponsiveMediaQuery,UserProfile,Functions} = Applicationstate

const {CompanyProfile} = UserProfile

const {registration_link,theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 

const {mobile_xs,tablet_sm} = ResponsiveMediaQuery



const History = useLocation()

const {pathname} = History

const [ServiceList, setServiceList]=React.useState([
  {image:'images/InteriorDesign.jpeg',title:'Interior Design',description:'JengaAfrica Accept we offers best interior design  models to best fit modern construction design and satisfies  our clients demands as well as qualifies modern standards'},
  {image:'images/ResidentialAppartments.jpg',title:'Residential Appartments',description:'JAABC offers and participates in constructions of residential apartments that suit most of our national community demand.'},
  {image:'images/Construction-Supervision.jpg',title:'Construction Supervision',description:'JAABC we offers Construction Project Supervision , Monitoring and evaluation to the construction site to ensure high building standards have been met as well as to ensure genuine material supply to the site.'},
]);




const [SERVICE_LIST_COLLECTION, SET_SERVICE_LIST_COLLECTION]=React.useState([
  {
   title:'Sales & Marketing Training Programs',
   image:'image/service/sales&marketing.jpg',
   description:`
      Our Sales & Marketing Training Programs provide an extensive curriculum designed
      to elevate your skills in selling and branding products. These programs offer expert-led 
      sessions covering the latest strategies in market analysis, customer engagement, 
      digital marketing, and brand management. With a focus on practical applications,
      our training includes hands-on workshops, real-world case studies, and interactive
      exercises to ensure you can effectively implement what you learn. The aim is to 
      create proficient sales and marketing professionals, equipping you with the knowledge
      and tools necessary to excel in the competitive landscape and exposing you to available
      opportunities for career advancement and business success.
   `
  },
  {
   title:'Entrepreneurship & Mentorship Program',
   image:'image/service/Entrepreneurshi&Mentorship.jfif',
   description:`
   Our Entrepreneurship & Mentorship Program is designed to empower aspiring entrepreneurs
    with the skills, knowledge, and guidance they need to succeed. This program offers a 
    unique blend of comprehensive training, one-on-one mentoring, and networking opportunities 
    with industry leaders. Participants will learn how to develop business plans, secure funding,
     and effectively market their ideas. Our experienced mentors provide personalized advice, 
     helping you navigate challenges and seize opportunities. Join our program to gain the 
     confidence and competence to launch and grow your business, and connect with a supportive 
     community of like-minded professionals
   `
  },
]);


const [PRODUCT_LIST_COLLECTION, SET_PRODUCT_LIST_COLLECTION]=React.useState([
  {
   title:'Sales & Marketing Training Programs',
   image:'image/service/sales&marketing.jpg',
   description:`
      Our Sales & Marketing Training Programs provide an extensive curriculum designed
      to elevate your skills in selling and branding products. These programs offer expert-led 
      sessions covering the latest strategies in market analysis, customer engagement, 
      digital marketing, and brand management. With a focus on practical applications,
      our training includes hands-on workshops, real-world case studies, and interactive
      exercises to ensure you can effectively implement what you learn. The aim is to 
      create proficient sales and marketing professionals, equipping you with the knowledge
      and tools necessary to excel in the competitive landscape and exposing you to available
      opportunities for career advancement and business success.
   `
  },
  {
   title:'Entrepreneurship & Mentorship Program',
   image:'image/service/Entrepreneurshi&Mentorship.jfif',
   description:`
    Our Entrepreneurship & Mentorship Program is designed to empower aspiring entrepreneurs
    with the skills, knowledge, and guidance they need to succeed. This program offers a 
    unique blend of comprehensive training, one-on-one mentoring, and networking opportunities 
    with industry leaders. Participants will learn how to develop business plans, secure funding,
    and effectively market their ideas. Our experienced mentors provide personalized advice, 
    helping you navigate challenges and seize opportunities. Join our program to gain the 
    confidence and competence to launch and grow your business, and connect with a supportive 
    community of like-minded professionals
   `
  },
]);









useEffect(()=>{ //COMPONENT DID MOUNT



return()=>{//<<<<<<<<<<<----------------Component Will Unmount


}
},[]) // eslint-disable-line react-hooks/exhaustive-deps






const SERVICE_LIST_COLLECTION_MAP = SERVICE_LIST_COLLECTION.map(function(object,index){
return (
  <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s" key={index}>
  <div className="room-item shadow rounded overflow-hidden">
    <div className="position-relative">
      <img className="img-fluid" src={object.image} alt />
      <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">Free/Entry</small>
    </div>
    <div className="p-4 mt-2">
      <div className="d-flex justify-content-between mb-3">
        <h5 className="mb-0">{object.title}</h5>
        <div className="ps-2">
          <small className="fa fa-star text-primary" />
          <small className="fa fa-star text-primary" />
          <small className="fa fa-star text-primary" />
          <small className="fa fa-star text-primary" />
          <small className="fa fa-star text-primary" />
        </div>
      </div>
      <div className="d-flex mb-3">
        <small className="border-end me-3 pe-3"><i className="fa fa-bed text-primary me-2" />Training</small>
        <small className="border-end me-3 pe-3"><i className="fa fa-bath text-primary me-2" />Course</small>
        <small>Free</small>
      </div>
       <p className="text-body mb-3">
         {object.description}   
       </p>
      <div className="d-flex justify-content-between">
        <a className="btn btn-sm btn-primary rounded py-2 px-4" href>View Detail</a>
        <a className="btn btn-sm btn-dark rounded py-2 px-4" href>JoinNow</a>
      </div>
    </div>
  </div>
</div>
)


})

const PRODUCT_LIST_COLLECTION_MAP = PRODUCT_LIST_COLLECTION.map(function(object,index){
  return (
    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s" key={index}>
    <div className="room-item shadow rounded overflow-hidden">
      <div className="position-relative">
        <img className="img-fluid" src={object.image} alt />
        <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">Free/Entry</small>
      </div>
      <div className="p-4 mt-2">
        <div className="d-flex justify-content-between mb-3">
          <h5 className="mb-0">{object.title}</h5>
          <div className="ps-2">
            <small className="fa fa-star text-primary" />
            <small className="fa fa-star text-primary" />
            <small className="fa fa-star text-primary" />
            <small className="fa fa-star text-primary" />
            <small className="fa fa-star text-primary" />
          </div>
        </div>
        <div className="d-flex mb-3">
          <small className="border-end me-3 pe-3"><i className="fa fa-bed text-primary me-2" />Training</small>
          <small className="border-end me-3 pe-3"><i className="fa fa-bath text-primary me-2" />Course</small>
          <small>Free</small>
        </div>
         <p className="text-body mb-3">
           {object.description}   
         </p>
        <div className="d-flex justify-content-between">
          <a className="btn btn-sm btn-primary rounded py-2 px-4" href>View Detail</a>
          <a className="btn btn-sm btn-dark rounded py-2 px-4" href>JoinNow</a>
        </div>
      </div>
    </div>
  </div>
  )
  
  
})


return ( ///-------------- RETURN HTML------------------
<Fragment>
{pathname==='/services'?

<TopBanner Title='Services' CurrentPage='our services' />

:null}
<div className="container-xxl py-5">
    <div className="container">
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 className="section-title text-center text-primary text-uppercase">Our Services</h6>
        <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Services</span></h1>
      </div>
      <div className="row g-4">

        {SERVICE_LIST_COLLECTION_MAP}
        
      </div>
    </div>
  </div>
  {/* Room End */}
</Fragment>
);

// Services.propTypes = {}
          
// Services.defaultProps ={}



}
export{Services}

