import React ,{Fragment,useEffect}from 'react';
import './TopBanner.scss';
import $ from 'jquery';
import {useHistory,useLocation} from "react-router-dom";
//import {http_worker,indexedb_worker} from "../worker";

//import {makeStyles} from "@material-ui/core";
// import {NotificationImportant} from "@material-ui/icons";

// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import Checkbox from '@material-ui/core/Checkbox';
// import {Typography,Container} from '@material-ui/core';





const  TopBanner = ({CurrentPage,Title}) => {

const [TopBannerx, setProductDetail] = React.useState(
  
  {
  name:'FOREVER ALOE VERA GEL',
  main_functionality:null,
  price:0,
  main_image:'TopBanner/FOREVER-ALOEVERA-GEL.jpg',
  image_collection:[],
  description:'Forever Aloe Vera Gel is a pure aloe drink with 99.7% aloe vera gel! The gel comes directly from our own plantations in Texas and the Dominican Republic. Forever Aloe Vera Gel is rich in vitamin C, is sugar-free and contains no preservatives.',
  feature_inclusive:[]
  }

);


const Location = useLocation()

const {state} = Location



useEffect(()=>{ //COMPONENT DID MOUNT

  //$(window).scrollTop(20)//scroll to top of the component





return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps










return( ///-------------- RETURN HTML------------------
<Fragment>
<div className="container-fluid page-header mb-5 p-0" style={{backgroundImage: 'url(img/carousel-1.jpg)'}}>
  <div className="container-fluid page-header-inner py-5">
    <div className="container text-center pb-5">
      <h1 className="display-3 text-white mb-3 animated slideInDown">{Title}</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb justify-content-center text-uppercase">
          <li className="breadcrumb-item"><a href="#">Home</a></li>
          <li className="breadcrumb-item"><a href="#">{CurrentPage}</a></li>

          {/* <li className="breadcrumb-item text-white active" aria-current="page">Testimonial</li> */}
        </ol>
      </nav>
    </div>
  </div>
</div>
</Fragment>
);

// TopBanner.propTypes = {}
          
// TopBanner.defaultProps ={}



}
export{TopBanner}

