import React ,{Fragment,useEffect,useContext}from 'react';
import './Project.scss';
import $ from 'jquery';
import {useHistory,useLocation,useParams,Link} from "react-router-dom";
import Grid from '@mui/material/Grid/Grid';
import {AppContextApi} from "../../AppContext";
import {TopBanner} from "../TopBanner/TopBanner";
import {TaskAlt,CheckCircle} from '@mui/icons-material';
import { Typography } from '@mui/material';



const  Project = ({props}) => {
  
  const Applicationstate = useContext(AppContextApi)

  const {ResponsiveMediaQuery,UserProfile,Functions} = Applicationstate
  
  const {CompanyProfile} = UserProfile
  
  const {theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 
  
  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery
  
  
  
  const History = useLocation()
  
  const {pathname} = History
  
  const [ProjectList, setProjectList]=React.useState([
    {
     title:'Residential Project (1)',
     image:'images/project-1.jpg',
     construction_period:'8 Month',
     completion_time:'Jan 2020', 
     employer:'Africa Rlief, Foundation Africa , P.O.Box 5645 Dar Es Salaam',
     description:`Project executed and supervised by Africa Accept Agency up to completion according to our contract and were handled to the owner with a succession status ,we ensure all building procedure were correct implement to achieve maximum and astonishing outcomes.`
    },
    {
     title:'Residential Project (2)',
     image:'images/project-2.jpg',
     construction_period:'4 Months',
     completion_time:'March 2020', 
     employer:'Vietel Tanzania plc',
     description:'Project executed and supervised by Africa Accept Agency up to completion according to our contract and were handled to the owner with a succession status ,we ensure all building procedure were correct implement to achieve maximum and astonishing outcomes.'
    },
    {
     title:'Residential Project (3)', 
     image:'images/project-3.jpg',
     construction_period:'6 Month',
     completion_time:'January,2020', 
     employer:'The Registered Trustees of Ishik  Medical & Education Foundation ,P.O.BOX 54254 ,Dar es Salaam.',
     description:`Project executed and supervised by Africa Accept Agency up to completion according to our contract and were handled to the owner with a succession status ,we ensure all building procedure were correct implement to achieve maximum and astonishing outcomes.`
    },
  ]);
  

useEffect(()=>{ //COMPONENT DID MOUNT

  //$(window).scrollTop(20)//scroll to top of the component





return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps





const PROJECT_LIST_MAP = Object.keys(ProjectList).map(function(key,index){
return (
  <div key={index} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
  <div className="blog-item">
    <div className="blog-img">
      <img src={ProjectList[index].image}  style={{maxWidth:'100%',height:300}} alt="Image" />
    </div>
    <div className="blog-title">
      <h3>{ProjectList[index].title}</h3>
      <a className="btn" href>+</a>
    </div>
    <div className="blog-text">
      <p>
       {ProjectList[index].description}
      </p>

    </div>
  </div>
</div>


  )
})
  





return ( ///-------------- RETURN HTML------------------
<Fragment>
<TopBanner PathName={'Projects Acomplished'} />
<div className="blog">
  <div className="container">
    <div className="section-header text-center">
       <p>Latest Accomplished project</p>
      <h2>Completed projects</h2>
    </div>
    <div className="row blog-page">


     {PROJECT_LIST_MAP}


    </div>
    <div className="row">
      <div className="col-12">
        <ul className="pagination justify-content-center">
          <li className="page-item disabled"><a className="page-link" href="#">Previous</a></li>
          <li className="page-item"><a className="page-link" href="#">1</a></li>
          <li className="page-item active"><a className="page-link" href="#">2</a></li>
          <li className="page-item"><a className="page-link" href="#">3</a></li>
          <li className="page-item"><a className="page-link" href="#">Next</a></li>
        </ul> 
      </div>
    </div>
  </div>
</div>





</Fragment>
);

// Project.propTypes = {}
          
// Project.defaultProps ={}



}
export{Project}

