import React ,{Fragment,useEffect,useContext}from 'react';
import './CompanyObjective.scss';
import $ from 'jquery';
import {useHistory,useLocation,useParams,Link} from "react-router-dom";
import Grid from '@mui/material/Grid/Grid';
import {AppContextApi} from "../../AppContext";
import {TopBanner} from "../TopBanner/TopBanner";
import {TaskAlt,CheckCircle} from '@mui/icons-material';
import { Typography } from '@mui/material';



const  CompanyObjective=({props})=>{
  
  const Applicationstate = useContext(AppContextApi)

  const {ResponsiveMediaQuery,UserProfile,Functions} = Applicationstate
  
  const {CompanyProfile} = UserProfile
  
  const {theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 
  
  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery
  
  
  
  const History = useLocation()
  
  const {pathname} = History
  
  const [ProjectList, setProjectList]=React.useState([
    {
     title:'Create Sales and Marketing Opportunities',
     image:'svg/Quality-Construction.svg',
     description:`
     We focus on generating valuable sales and marketing opportunities tailored to meet the unique
     needs of our clients..
     `
    },
    {
     title:'Provide Business Training',
     image:'svg/Cost-Control.svg',
     description:`
     Our comprehensive business training programs are designed to equip clients with
      the skills and knowledge necessary to maximize their potential.
     `
    },
    {
     title:'Prepare Clients for Business Challenges', 
     image:'svg/Construction-Innovation.svg',
     description:`
     We prepare our clients to face and overcome various business challenges through
      strategic guidance and practical solutions.
     `
    },
    {
      title:'Unveil Opportunities for Clients', 
      image:'svg/Construction-Innovation.svg',
      description:`
      Our team actively uncovers and presents existing opportunities that clients can leverage
      for immediate benefit and growth.
      `
     },
     {
      title:'Foster Networking and Growth', 
      image:'svg/Construction-Innovation.svg',
      description:`
      We create a supportive and collaborative environment that encourages networking and 
      professional development among clients.
      `
     },
     {
      title:' Connect Business Entrepreneurs', 
      image:'svg/Construction-Innovation.svg',
      description:`
      We facilitate connections among business entrepreneurs, enabling them to build valuable
       business relationships and potential partnerships.
      `
     },
  ]);
  

useEffect(()=>{ //COMPONENT DID MOUNT

  //$(window).scrollTop(20)//scroll to top of the component





return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps





const OBJECTIVES_LIST_MAP = ProjectList.map(function(object,index){
return(

    <li key={index} style={{borderBottom:`2px solid ${theme_maincolor}`}}>
      
       <b> <div style={{width:15,height:15,backgroundColor:theme_maincolor}}></div>{object.title}</b> <br />
        <span>{object.description}</span>
    </li>
  )
})
  





return ( ///-------------- RETURN HTML------------------
<Fragment>
<div className="container-xxl py-5">
  <div className="container">
    <div className="text-center wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
      <h6 className="section-title text-center text-primary text-uppercase">Our  Objectives</h6>
      <h1 className="mb-5">Explore  Our <span className="text-primary text-uppercase">Objectives</span></h1>
    </div>
    
    <div className="row g-5">
      <div className="col-lg-6">
        <div className="row g-3">
          <div className="col-6 text-end">
            <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.1s" src="image/objective-2.jpg" style={{marginTop: '25%', visibility: 'visible', animationDelay: '0.1s', animationName: 'zoomIn'}} />
          </div>
          <div className="col-6 text-start">
            <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s" src="image/objective-1.jpg" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'zoomIn'}} />
          </div>
          <div className="col-6 text-end">
            <img className="img-fluid rounded w-50 wow zoomIn" data-wow-delay="0.5s" src="image/objective-3.webp" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'zoomIn'}} />
          </div>
          <div className="col-6 text-start">
            <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.7s" src="image/objective-4.PNG" style={{visibility: 'visible', animationDelay: '0.7s', animationName: 'zoomIn'}} />
          </div>
        </div>
      </div>


      <div className="col-lg-6">
       <div className="wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
       <p className="mb-4"> 
       Our company is dedicated to empowering sales and marketing professionals by providing
        them with the tools and opportunities they need to succeed. Below are our key objectives:
          
        </p>
         <ul className='objectives-list'>
           {OBJECTIVES_LIST_MAP}
         </ul>
        </div>
      </div>
    </div>




    <div className="text-center wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
      <h6 className="section-title text-center text-primary text-uppercase">Our  Motto</h6>
      <h1 className="mb-5">Femsbay <span className="text-primary text-uppercase"> WE WORKS AND GROW TOGETHER</span></h1>
    </div>

  </div>
</div>




</Fragment>
);

// CompanyObjective.propTypes = {}
          
// CompanyObjective.defaultProps ={}



}
export{CompanyObjective}

