import React ,{Fragment,useEffect,useContext}from 'react';
import './CompanyCoreValue.scss';
import $ from 'jquery';
import {useHistory,useLocation,useParams,Link} from "react-router-dom";
import Grid from '@mui/material/Grid/Grid';
import {AppContextApi} from "../../AppContext";
import {TopBanner} from "../TopBanner/TopBanner";
import {TaskAlt,CheckCircle} from '@mui/icons-material';
import { Typography } from '@mui/material';



const  CompanyCoreValue=({props})=>{
  
  const Applicationstate = useContext(AppContextApi)

  const {ResponsiveMediaQuery,UserProfile,Functions} = Applicationstate
  
  const {CompanyProfile} = UserProfile
  
  const {theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 
  
  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery
  
  const History = useLocation()
  
  const {pathname} = History
  
  const [CORE_VALUES_LIST, SET_CORE_VALUES_LIST]=React.useState([
    {
     title:'Opportunity Exploration',
     image:'image/core_values/opportunity-exploration.webp',
     description:`
     We are dedicated to diligently exploring, unveiling, and uncovering available sales
      and marketing opportunities from various businesses. Our proactive approach ensures 
      we stay ahead of the curve and continuously identify new avenues for growth and success
       of our sales and marketing professionals.
     `
    },
    {
     title:'Professional Empowerment',
     image:'image/core_values/professional-empowerment.png',
     description:`
     We equip our sales and marketing professionals with the skills, knowledge, 
     and resources necessary to effectively harness and maximize available opportunities.
     `
    },
    {
     title:'Empowerment through Readiness', 
     image:'image/core_values/empowerment.svg',
     description:`
      Our mission is to make every opportunity ready and accessible for our sales and marketing
      professionals. We equip them with the resources, knowledge, and tools they need to leverage
      these opportunities effectively, ensuring their benefits come first.
     `
    },
    {
      title:'Recognition and Reward', 
      image:'image/core_values/reward.svg',
      description:`
      We celebrate the achievements and hard work of our sales force. By offering competitive 
      commissions and rewards, we ensure that their efforts are acknowledged and appreciated,
       motivating them to continue striving for excellence
      `
     },
     {
      title:'Integrity', 
      image:'image/core_values/integrity.png',
      description:`
      We conduct our business with honesty and transparency, building trust with our clients, 
      partners, and within our professional network.
      `
     },
     {
      title:'Collaboration', 
      image:'image/core_values/collaboration.svg',
      description:`
       We foster a collaborative by adhering to our motor of “we work and grow together” 
       where our professionals can share insights, support each other, and collectively 
       achieve superior results.
      `
     },
  ]);
  

useEffect(()=>{ //COMPONENT DID MOUNT

  //$(window).scrollTop(20)//scroll to top of the component





return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps





const OBJECTIVES_LIST_MAP = CORE_VALUES_LIST.map(function(object,index){
return(
    <Grid key={index} item xs={12} sm={4}>
       <b style={{fontSize:'1.5rem',color:theme_maincolor}}>{object.title}</b> <br />
        <div className='corevalueFrame'>
          <div className='CorevalueIcon'><img src={object.image} style={{maxWidth:'30%'}}/></div>
        </div>
        <span>{object.description}</span>
    </Grid>
  )
})
  





return ( ///-------------- RETURN HTML------------------
<Fragment>
<div className="container-xxl py-5">
  <div className="container">
    <div className="text-center wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
      <h6 className="section-title text-center text-primary text-uppercase">Our  Core Value</h6>
      <h1 className="mb-5">Our <span className="text-primary text-uppercase">Core  Values</span></h1>
    </div>
    
    <div className="row g-5">
      
        <div className="col-lg-12">
       <div className="wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
       <p className="mb-4"> 
         our core values are centered on the relentless pursuit of marketing opportunities 
         and the empowerment of our sales and marketing professionals. We focus on innovation, excellence, 
         and integrity to explore, create, and optimize marketing opportunities. Our commitment to 
         collaboration and continuous improvement ensures our network of professionals is always ready
          to capitalize on these opportunities for maximum impact.
          
        </p>
        <Grid container spacing={2}>

           {OBJECTIVES_LIST_MAP}

           </Grid>
         
        </div>
      </div>

    </div>
  </div>
</div>




</Fragment>
);

// CompanyCoreValue.propTypes = {}
          
// CompanyCoreValue.defaultProps ={}



}
export{CompanyCoreValue}

