import React ,{Fragment,useEffect,useContext}from 'react';
import './Homepage.scss';
import $ from 'jquery';
import {useHistory,useLocation,useParams,Link} from "react-router-dom";
import {AppContextApi} from "../../AppContext";
import {AboutPage} from "../AboutPage/AboutPage";
import {Services} from "../Services/Services";
import Typography from '@mui/material/Typography';
import {OurTeam} from "../OurTeam/OurTeam";
import {CompanyObjective} from "../CompanyObjective/CompanyObjective";





const  Homepage = ({props}) => {

  const Applicationstate = useContext(AppContextApi)
  const {ResponsiveMediaQuery,UserProfile,Functions} = Applicationstate
  const {CompanyProfile} = UserProfile
  const {registration_link,theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 
  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery
  
  const {company_logo} = CompanyProfile
  
  
  
  const History = useLocation()
  
  const {pathname} = History
  
  
  


useEffect(()=>{ //COMPONENT DID MOUNT

  //$(window).scrollTop(20)//scroll to top of the component





return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps










return ( ///-------------- RETURN HTML------------------
<Fragment>
{/* Carousel Start */}
   <div className="container-fluid p-0 mb-5">
    <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        
        <div className="carousel-item active" style={{maxHeight:500}}>
          <img className="w-100" src="image/carousel-1.png" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: 700}}>
              <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">Fast Growing Network</h6>
              <h1 className="display-3 text-white mb-4 animated slideInDown">A fast Growing Network of sales & marketing professionals</h1>
              <a href='#' className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Join Us</a>
              <a href='#' className="btn btn-light py-md-3 px-md-5 animated slideInRight">Call us</a>
            </div>
          </div>
        </div>


        <div className="carousel-item" style={{maxHeight:500}}>
          <img className="w-100" src="image/carousel-2.jpg" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: 700}}>
              <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">Sales & Marketing</h6>
              <h1 className="display-3 text-white mb-4 animated slideInDown">We unlock sales and marketing opportunities for your success.</h1>
              <a href className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Join Us</a>
              <a href className="btn btn-light py-md-3 px-md-5 animated slideInRight">Call Us</a>
            </div>
          </div>
        </div>



      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  {/* Carousel End */}

  <AboutPage />

<div className="container-xxl py-5 px-0 wow zoomIn" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'zoomIn'}}>
  <div className="row g-0">
    <div className="col-md-6 bg-dark d-flex align-items-center">
      <div className="p-5">
        <h6 className="section-title text-start text-white text-uppercase mb-3">sales & marketing</h6>
        <h1 className="text-white mb-4">Join our fast-growing network of sales & marketing professionals</h1>
        <p className="text-white mb-4">
          Join our rapidly expanding network of sales and marketing professionals! 
          Connect with industry experts, gain valuable insights, and accelerate your
           career growth. Be part of a vibrant community dedicated to success and innovation."

         </p>
        <a href={registration_link} className="btn btn-primary py-md-3 px-md-5 me-3">Register Now</a>
        <a href className="btn btn-light py-md-3 px-md-5">Contact Us</a>
      </div>
    </div>
    <div className="col-md-6">
      <div className="video">
        <button type="button" className="btn-play" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
          <span />
        </button>
      </div>
    </div>
  </div>
</div>

  <Services />

  <CompanyObjective />

  </Fragment>
);

// AboutPage.propTypes = {}
          
// AboutPage.defaultProps ={}



}
export{Homepage}

